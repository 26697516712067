import React, { PureComponent, useState, useCallback, useEffect } from "react";
// import CountDownBlock from "./CountDownBlock";

import Link from "../LinkWithTransitionLock"
import Helmet from "react-helmet"
import InViewObserver from "../InViewObserver"

import { Expo, gsap } from 'gsap';

class Page extends PureComponent {
  constructor(props) {
     super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap">

        <div className="page_header">
          <h2 className="sub_title">20 X 20 / 上半場</h2>
          <h1 className="main_title">沈伯丞</h1>
        </div>

        <article className="page_content blog_single_post_content">

          <section className="row_area_s">
            <div className="column_5">
                <div className="context">
                  <p>傳統的公共藝術是在公共空間放置裝置與雕塑，但是社宅是一個生命集合體，策展人沈伯丞提出，將社宅建築的整體空間看作一個生態系，以對應已然完全不是第一自然原始自然的都會來說，來想像社宅空間與公共藝術可以形成什麼模樣。</p>
                  <p>透過公共藝術引進各種人為製造的生態系，使其他生物和人類共生，例如Bird feeder、養蜂場、雨水花園、一個擁有動植物或昆蟲的小型自然博物館。如環境藝術家Vaughn Bell與植物學家Pati Vitt的計畫─Local Home，將雨水花園設計轉化為作品展出。或東京都的摩天樓種蜜園，利用透明蜂巢可觀察到另一個昆蟲社群，體覺除了人類還有另外的動物社群生活於一個共同環境中。沈伯丞再舉《Sierra Remote Observatories》一個於社區大樓頂端設置望遠鏡的藝術計畫，提供住戶觀察接觸該生活區域、不同季節的星空；而展覽《Art as Aftershock》將地震紀錄轉化成圖像、聲音，讓人聽見、看見該場域曾發生大地震動的變化。透過這些計畫進一步思考、感覺自然與氣候，或許可以帶來觀看生活空間不同的視角，也可以是凝聚社區共同意識的方式。</p>
                  <p>藝術也可以紀錄生活圈的狀態，ILYA KABAKOV將蘇聯時代在集合住宅生活的人的意象創作為裝置作品。沈伯丞認為社宅公共藝術也可以把不同人的故事轉化成創作，讓人理解其他不同而特殊的生活型態。</p>
                  <p className="onset_right_1">而社宅公共藝術不應該只有視覺、環境方面的呈現，也可以有音樂的呈現。若是設置一個小的表演廳，表演藝術也可以在小的社區空間或者是公寓空間裡面完成一個計畫。</p>
                  <p className="onset_right_1">最後透過分享藝術家ROGER HIORNS作品〈Seizure〉──以硫酸銅結晶灌入舊公寓空間形成的藍色晶體空間──沈伯丞進一步闡述，在這種生態系的概念下，融合藝術的精煉結晶，提供一種超越性與精神性的空間；回溯人類早期居住空間文化，一萬五千年前，人類在洞穴中分享記憶、知識、情感，世界的每個部份都跟人類、跟藝術相關。而當代也需要一個將世俗生活精神化完美詮釋的空間，我們可以用這個角度來思考這個空間，回到一個全新的神祕時代。</p>
                </div>
            </div>
          </section>

        </article>

        <div className="page_footer">
          <Link to="/blog/post_1_2" className="next_page">
            <div className="next_title">林長叡</div>
            <div className="next_owner">20 X 20 / 上半場</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
